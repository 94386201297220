import { useState, useContext, useEffect } from "react";
import {
  JustifyContent,
  Color,
  ColorPreset,
  AlignItems,
  CSSRulesFunction,
  useTheme,
  ZIndex,
  Layer,
  Grid,
  Visibility,
  Interpose,
  Space,
  Container,
  Column,
  Box,
  ColorProp,
  ContainerProps,
  BoxProps,
  Separator,
  ButtonLayout,
  PlainLink,
  Breakpoint,
} from "@gocardless/flux-react";

import GoCardlessLogo from "../GoCardlessLogo";
import { Sidebar, MobilePrimaryNavigation } from "../navigation";
import { SiteSettingsDropdown, SiteSettingsDialog } from "../site-settings";
import { LinkBuilder, Route } from "../routing";
import { GlobalState } from "../global-state/GlobalStateProvider";
import { SearchFormContainer } from "../search";
import { useMultiAccountFeature } from "../routes/Accounts/hooks/useMultiAccountFeature";
import { useIsEligibleForReferral } from "../routes/Refer/hooks/useIsEligibleForReferral";
import { OrganisationSwitcher } from "../routes/Accounts/OrganisationSwitcher/OrganisationSwitcher";

import ReferButton from "./ReferButton";
import ChooseReferralRewardReferButton from "./ChooseReferralRewardReferButton";

import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import ExtendDiscountDialog from "src/components/incentive-banner/ExtendDiscountDialog";
import ExtendDiscountBanner from "src/components/incentive-banner/ExtendDiscountBanner";
import ExtendDiscountButton from "src/components/incentive-banner/ExtendDiscountButton";

const PAGE_TITLE_BAR_HEIGHT = "55";

const fullWidthStyle: CSSRulesFunction<{
  position: "fixed" | "relative";
  bg?: Color | ColorPreset;
}> = (theme, { position, bg }) => [
  theme.responsive([null, null, null, "240px", "264px"], (v) => ({
    width: v ? `calc(100% - ${v})` : "100%",
  })),
  {
    width: "100%",
    height: `${PAGE_TITLE_BAR_HEIGHT}px`,
    backgroundColor: theme.color(bg || ColorPreset.BackgroundLight_02),
    position: position,
  },
];

export enum PrimaryPageLayoutWidth {
  FULL_WIDTH = "100%",
  WIDE = "1800px",
  MEDIUM = "1296px",
  NARROW = "840px",
}

export interface PrimaryPageLayoutProps {
  bg?: ColorProp;
  highlightedMenuLink?: Route;
  children?: React.ReactNode;
  gutterH?: ContainerProps["contentGutters"];
  gutterV?: BoxProps["gutterV"];
  maxWidth?: PrimaryPageLayoutWidth;
  breadcrumbs?: React.ReactNode;
  showExtendDiscount?: boolean;
  ensureFullHeight?: boolean;
}

export type PageTitleBarProps = Pick<
  BoxProps,
  "bg" | "elevation" | "borderRadius"
> & {
  highlightedMenuLink?: Route;
  breadcrumbs?: React.ReactNode;
  showExtendDiscount?: boolean;
};

const primaryPageContentStyle: CSSRulesFunction<PrimaryPageLayoutProps> = (
  theme,
  { bg }
) => [
  theme.responsive([null, null, null, "240px", "264px"], (v) => ({
    marginLeft: v ? `${v}` : 0,
    width: v ? `calc(100% - ${v})` : "100%",
  })),
  {
    width: "100%",
    position: "relative",
    background: theme.color(bg || ColorPreset.BackgroundLight_02),
    borderRadius: "0 0 0 24px",
  },
];

const containerStyle: CSSRulesFunction<{ ensureFullHeight?: boolean }> = (
  theme,
  { ensureFullHeight }
) => [
  theme.responsive(
    [null, null, null, PAGE_TITLE_BAR_HEIGHT, PAGE_TITLE_BAR_HEIGHT],
    (h) => ({
      marginTop: h ? `${h}px` : 0,
    })
  ),
  {
    background: theme.color(ColorPreset.BackgroundLight_02),
    height: ensureFullHeight ? "100%" : "auto",
  },
];

const leftAlignedContainerStyle: CSSRulesFunction = () => ({
  marginLeft: 0,
});

export const PageTitleBarWithControls: React.FC<PageTitleBarProps> = ({
  bg,
  elevation,
  highlightedMenuLink,
  borderRadius,
  breadcrumbs,
  showExtendDiscount = false,
}) => {
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [openExtendDiscountDialog, toggleExtendDiscountDialog] =
    useState(false);

  const { hasMultiAccountFeature } = useMultiAccountFeature();

  const { theme } = useTheme();

  const isEligibleForReferralScheme = useIsEligibleForReferral();

  const { isVariationOn: isChooseReferralRewardEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.SPARK_CHOOSE_REFERRAL_REWARD_V1,
    });

  const { isVariationOn: isDoubleRewardsPromotionEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.SPARK_DOUBLE_REWARDS_PROMOTION,
    });

  const isBelowXlBreakpoint = useIsBreakpoint({ max: Breakpoint.Lg });

  return (
    <>
      <Visibility
        visible={["block", null, null, "none"]}
        css={fullWidthStyle(theme, {
          position: "relative",
        })}
      >
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.SpaceBetween}
          elevation={elevation}
          bg={bg}
          height={`${PAGE_TITLE_BAR_HEIGHT}px`}
          gutterV={1}
          gutterH={1}
          width="100%"
          css={{ position: "fixed", zIndex: 350 }}
        >
          <Visibility
            // @ts-expect-error - "flex" is not included in VisibilityValue interface, will raise with UXI
            visible={[isSearchOpen ? "none" : "flex", "flex", null, "none"]}
          >
            {hasMultiAccountFeature ? (
              <OrganisationSwitcher />
            ) : (
              <LinkBuilder route={Route.Home}>
                {(result) => (
                  <PlainLink {...result} layout={ButtonLayout.Inline}>
                    <GoCardlessLogo variant="dark" size="sm" />
                  </PlainLink>
                )}
              </LinkBuilder>
            )}
          </Visibility>
          <Box
            layout="flex"
            width="100%"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.FlexEnd}
          >
            {isEligibleForReferralScheme &&
            (isDoubleRewardsPromotionEnabled ||
              isChooseReferralRewardEnabled) ? (
              <ChooseReferralRewardReferButton />
            ) : null}
            <SearchFormContainer
              isMobile={true}
              isOpen={isSearchOpen}
              setOpen={setSearchOpen}
            />

            {isBelowXlBreakpoint && (
              <Box layout="flex">
                <SiteSettingsDialog />
                <MobilePrimaryNavigation
                  highlightedMenuLink={highlightedMenuLink}
                />
              </Box>
            )}
          </Box>
        </Box>
        {showExtendDiscount && (
          <ExtendDiscountBanner
            onClickExtend={() => toggleExtendDiscountDialog(true)}
          />
        )}
      </Visibility>
      <Layer mode="relative" zIndex={350 as ZIndex}>
        <Visibility
          visible={["none", null, null, "block"]}
          css={fullWidthStyle(theme, {
            position: "fixed",
            bg: Color.Slate_1100,
          })}
        >
          <Box
            layout="flex"
            alignItems={AlignItems.Center}
            justifyContent={
              breadcrumbs ? JustifyContent.SpaceBetween : JustifyContent.FlexEnd
            }
            elevation={elevation}
            bg={bg}
            height={`${PAGE_TITLE_BAR_HEIGHT}px`}
            gutterV={1}
            borderRadius={borderRadius}
          >
            {breadcrumbs ? (
              <Box spaceBefore={3}>
                <nav aria-label="Breadcrumbs">{breadcrumbs}</nav>
              </Box>
            ) : null}
            <Container
              maxContentWidth="100%"
              contentGutters={[1, showExtendDiscount ? 1 : 3]}
              css={leftAlignedContainerStyle(theme)}
            >
              <Grid gutterH={0}>
                <Column size={12}>
                  <Box
                    layout="flex"
                    alignItems={AlignItems.Center}
                    justifyContent={JustifyContent.FlexEnd}
                  >
                    <Interpose node={<Space h={2} layout="inline" />}>
                      {isEligibleForReferralScheme ? (
                        isDoubleRewardsPromotionEnabled ||
                        isChooseReferralRewardEnabled ? (
                          <ChooseReferralRewardReferButton />
                        ) : (
                          <ReferButton />
                        )
                      ) : null}
                      <SearchFormContainer
                        isOpen={isSearchOpen}
                        setOpen={setSearchOpen}
                      />
                      <SiteSettingsDropdown />
                    </Interpose>
                    {showExtendDiscount && (
                      <ExtendDiscountButton
                        onClickExtend={() => toggleExtendDiscountDialog(true)}
                      />
                    )}
                  </Box>
                </Column>
              </Grid>
            </Container>
          </Box>
          <Separator spacing={[[0, 3, 0.5, 3]]} />
        </Visibility>
      </Layer>
      {showExtendDiscount && (
        <ExtendDiscountDialog
          open={openExtendDiscountDialog}
          toggleDialog={() =>
            toggleExtendDiscountDialog(!openExtendDiscountDialog)
          }
        />
      )}
    </>
  );
};

export const PrimaryPageLayout: React.FC<PrimaryPageLayoutProps> = (props) => {
  const { theme } = useTheme();
  const {
    breadcrumbs,
    gutterH,
    gutterV,
    highlightedMenuLink,
    maxWidth,
    showExtendDiscount = false,
    ensureFullHeight = false,
  } = props;
  const { bannersHeight, setShowLayoutBanners } = useContext(GlobalState);

  useEffect(() => {
    setShowLayoutBanners(true);
    return () => setShowLayoutBanners(false);
  }, [setShowLayoutBanners]);

  return (
    <>
      <Box
        css={{
          position: "relative",
          minHeight: `100vh`,
        }}
        layout="flex"
        justifyContent={JustifyContent.FlexStart}
        bg={ColorPreset.BackgroundLight_02}
      >
        <Visibility visible={["none", null, null, "inline-block"]}>
          <Sidebar
            width="208px"
            highlightedMenuLink={highlightedMenuLink}
            css={{ paddingTop: `${bannersHeight}px` }}
            height={`calc(100% - ${bannersHeight}px)`}
          />
        </Visibility>
        <Box css={primaryPageContentStyle(theme, props)}>
          <PageTitleBarWithControls
            bg={ColorPreset.BackgroundLight_02}
            highlightedMenuLink={highlightedMenuLink}
            borderRadius={[[1.5, 0, 0, 0]]}
            breadcrumbs={breadcrumbs}
            showExtendDiscount={showExtendDiscount}
          />
          <Container
            maxContentWidth={maxWidth ?? PrimaryPageLayoutWidth.WIDE}
            contentGutters={gutterH ?? [1, 1, 1.5, 3]}
            css={containerStyle(theme, { ensureFullHeight })}
          >
            <Grid
              gutterH={0}
              css={{ height: ensureFullHeight ? "100%" : "auto" }}
            >
              <Column size={12}>
                {breadcrumbs ? (
                  <Visibility visible={["block", null, null, "none"]}>
                    <Space v={2} />
                    <nav aria-label="Breadcrumbs">{breadcrumbs}</nav>
                  </Visibility>
                ) : null}
                <Box
                  gutterV={gutterV ?? 1.5}
                  spaceBelow={1.5}
                  height={ensureFullHeight ? "100%" : "auto"}
                >
                  {props.children}
                </Box>
              </Column>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default PrimaryPageLayout;
