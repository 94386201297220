/* eslint-disable-next-line no-restricted-imports */
import {
  Glyph,
  Menu,
  MenuRole,
  MenuDensity,
  Box,
  useTheme,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useRef, useState } from "react";
import { useRouter } from "next/router";

import { LinkBuilder } from "../routing";
import { AdminRestriction } from "../restrictions";

import { useIsRestricted } from "src/common/hooks/useIsRestricted";
import { logout } from "src/common/authorisation";
import { Route } from "src/common/routing";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/queries/organisationType";
import { useResizeObserver } from "src/utils/geometry";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { useUserHasWritePermissions } from "src/common/hooks/user-permissions/useUserPermissions";

const customerHubLink = (language: string | null | undefined) =>
  language === "en"
    ? "https://hub.gocardless.com/s/knowledge-base"
    : "https://support.gocardless.com";

const emailStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const MENU_SCROLL_MARGIN = 100;
const MENU_BOTTOM_MARGIN = 200;

const SiteSettingsMenu: React.FC<{ isDropdown: boolean }> = ({
  isDropdown,
}) => {
  const [menuHeight, setMenuHeight] = useState<number>();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { data } = useUserShowSelf();
  const { organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;
  const createCreditorRestriction = useIsRestricted({ creditors: "create" });
  const { theme } = useTheme();
  const router = useRouter();
  const { sendEventPromise } = useSegment();
  const { hasWritePermissions } = useUserHasWritePermissions();

  const resizeCallback = () => {
    const element = menuRef.current;
    const rect = element?.getBoundingClientRect();

    const distanceToBottom =
      window.innerHeight -
      (rect ? rect?.top : element?.offsetTop || 0) -
      (element?.offsetHeight || 0);

    const calculatedHeight =
      distanceToBottom < MENU_SCROLL_MARGIN
        ? window.innerHeight - MENU_BOTTOM_MARGIN
        : rect
          ? rect.height
          : element?.offsetHeight || 0;

    if (isDropdown && calculatedHeight) {
      setMenuHeight(calculatedHeight);
    }
  };

  useResizeObserver(menuRef, resizeCallback, []);

  return (
    <Box ref={menuRef}>
      <Menu.Root
        id="site-settings"
        role={MenuRole.Menu}
        density={MenuDensity.Compact}
        css={[
          isDropdown ? { maxHeight: menuHeight, overflow: "auto" } : {},
          theme.responsive([null, null, null, "300px"], (minWidth) => ({
            minWidth,
          })),
        ]}
      >
        <Menu.Group
          title={`${data?.users?.given_name} ${data?.users?.family_name}`}
          description={
            <Box layout="block" css={emailStyles}>
              {data?.users?.email}
            </Box>
          }
          leftIcon={Glyph.User}
        >
          <LinkBuilder route={Route.Settings}>
            {(result) => (
              <Menu.Link {...result}>
                <Trans id="User Settings">User Settings</Trans>
              </Menu.Link>
            )}
          </LinkBuilder>
        </Menu.Group>

        <AdminRestriction>
          <Menu.Separator />
          <Menu.Group
            title={<Trans id="Company settings">Company settings</Trans>}
            leftIcon={Glyph.Company}
          >
            <>
              <LinkBuilder route={Route.CompanySettings}>
                {(result) => (
                  <Menu.Link {...result}>
                    <Trans id="Company info">Company info</Trans>
                  </Menu.Link>
                )}
              </LinkBuilder>
              {!isPaymentProvider ? (
                <LinkBuilder
                  route={Route.CompanyPlanAndAddons}
                  onClick={async () => {
                    await sendEventPromise(
                      TrackingEvent.MERCHANT_ONBOARDING_PLANS_AND_ADDONS_SETTINGS_CTA_CLICK,
                      { page: router.pathname }
                    );
                  }}
                >
                  {(result) => (
                    <Menu.Link {...result}>
                      <Trans id="plan-and-addons">Plan and add-ons</Trans>
                    </Menu.Link>
                  )}
                </LinkBuilder>
              ) : null}
              <LinkBuilder route={Route.Team}>
                {(result) => (
                  <Menu.Link {...result}>
                    <Trans id="Team">Team</Trans>
                  </Menu.Link>
                )}
              </LinkBuilder>
              {!isPaymentProvider && (
                <LinkBuilder route={Route.Brand}>
                  {(result) => (
                    <Menu.Link {...result}>
                      <Trans>Branding and customisation</Trans>
                    </Menu.Link>
                  )}
                </LinkBuilder>
              )}
              {!createCreditorRestriction.isRestricted && (
                <LinkBuilder route={Route.Creditors}>
                  {(result) => (
                    <Menu.Link {...result} data-testid="creditors-side-nav">
                      <Trans id="Creditors">Creditors</Trans>
                    </Menu.Link>
                  )}
                </LinkBuilder>
              )}
              {!isPaymentProvider && (
                <LinkBuilder route={Route.ConnectedApps}>
                  {(result) => (
                    <Menu.Link {...result}>
                      <Trans id="Connected apps">Connected apps</Trans>
                    </Menu.Link>
                  )}
                </LinkBuilder>
              )}
              {isPaymentProvider && (
                <LinkBuilder route={Route.CompanyConfigurations}>
                  {(result) => (
                    <Menu.Link {...result}>
                      <Trans>Configurations</Trans>
                    </Menu.Link>
                  )}
                </LinkBuilder>
              )}
            </>
          </Menu.Group>
        </AdminRestriction>

        <Menu.Separator />

        {hasWritePermissions && (
          <LinkBuilder route={Route.Imports}>
            {(result) => (
              <Menu.Link {...result}>
                <Trans id="Imports">Imports</Trans>
              </Menu.Link>
            )}
          </LinkBuilder>
        )}

        <Menu.Link
          href={customerHubLink(data?.users?.language)}
          target="_blank"
        >
          <Trans id="Help centre">Help centre</Trans>
        </Menu.Link>

        {!isPaymentProvider && (
          <LinkBuilder route={Route.WelcomeUsageSelection}>
            {(result) => (
              <Menu.Link {...result}>
                <Trans id="Search partner apps">Search partner apps</Trans>
              </Menu.Link>
            )}
          </LinkBuilder>
        )}

        <Menu.Link href="https://gocardless.com/legal" target="_blank">
          <Trans id="Legal">Legal</Trans>
        </Menu.Link>

        <Menu.Separator />

        <Menu.Button onClick={() => logout()}>
          <Trans id="Sign out">Sign out</Trans>
        </Menu.Button>
      </Menu.Root>
    </Box>
  );
};

export default SiteSettingsMenu;
